import { useStep } from "../../context/StepProvider";
import Step0 from "./step0";
import LeadsStep1 from "./leads/step1";
import LeadsStep2a from "./leads/step2a";
import LeadsStep2b from "./leads/step2b";
import LeadsStep3a1 from "./leads/step3a1";
import LeadsStep3a2 from "./leads/step3a2";
import LeadsStep3a3 from "./leads/step3a3";
import LeadsStep3a4 from "./leads/step3a4";
import LeadsStep3b1 from "./leads/step3b1";
import LeadsStep3b2 from "./leads/step3b2";
import LeadsStep3b3 from "./leads/step3b3";
import CooperationStep1 from "./cooperation/step1";
import CooperationStep2 from "./cooperation/step2";
import CustomersStep1 from "./customers/step1";
import CustomersStep2a from "./customers/step2a";
import CustomersStep2b from "./customers/step2b";
import CustomersStep2c from "./customers/step2c";
import CustomersStep2d from "./customers/step2d";
import HrStep1 from "./hr/step1";

const stepsMap: Record<string, React.FC> = {
  step0: Step0,
  leadsStep1: LeadsStep1,
  leadsStep2a: LeadsStep2a,
  leadsStep2b: LeadsStep2b,
  leadsStep3a1: LeadsStep3a1,
  leadsStep3a2: LeadsStep3a2,
  leadsStep3a3: LeadsStep3a3,
  leadsStep3a4: LeadsStep3a4,
  leadsStep3b1: LeadsStep3b1,
  leadsStep3b2: LeadsStep3b2,
  leadsStep3b3: LeadsStep3b3,
  cooperationStep1: CooperationStep1,
  cooperationStep2: CooperationStep2,
  customersStep1: CustomersStep1,
  customersStep2a: CustomersStep2a,
  customersStep2b: CustomersStep2b,
  customersStep2c: CustomersStep2c,
  customersStep2d: CustomersStep2d,
  hrStep1: HrStep1,
};

function Content() {
  const { currentStep } = useStep();
  const StepComponent = stepsMap[currentStep] || Step0;
  const { setStep } = useStep();

  return (
    <section className="min-h-screen flex flex-col items-center justify-center p-6 lg:p-12 relative">
      <div className="absolute top-6 left-6 flex flex-row items-center justify-start gap-6">
        <button
          onClick={() => setStep("step0")}
          className="button-dashboard active"
        >
          Powrót na start
        </button>
        <a href="/office/faq" className="button-dashboard active">
          Najczęściej zadawane pytania
        </a>
      </div>
      <StepComponent />
    </section>
  );
}

export default Content;
