import FaqList from "../compontents/office/faq";

function Faq() {
  return (
    <>
    <div className="w-screen bg-black h-screen flex flex-row items-stretch justify-between relative gap-6">
      <div className="w-full h-full absolute z-20 bg-gradient-to-br from-transparent to-[#6f00d8]/90 opacity-30"></div>
      <FaqList />
    </div>
  </>
  );
}

export default Faq;
