import { CiCirclePlus } from "react-icons/ci";
import { useState } from "react";

const questionsAndAnswers = [
  {
    question:
      "Jak ustawić automatyczną odpowiedź (autoresponder) na poczcie (e-mail)?",
    answer:
      "W tym celu należy skontaktować się ze swoim dostawcą poczty, który może zmienić ustawienia bądź dostarczyć odpowiedni poradnik.",
  },
  {
    question: "Mam problem z pocztą elektroniczną (e-mail)",
    answer:
      "W przypadku problemów z pocztą elektroniczną bądź serwerem należy skontaktować się z dostawcą usług hostingowych. Jeżeli posiadają Państwo konto w LH należy skontaktować się mailowo na adres info@lh.pl bądź telefonicznie pod numerem (+48) 61 624 00 00. W sytuacji, gdy posiadają Państwo konto w innej firmie hostingowej należy skontaktować się z ich działem wsparcia. Wynika to z tego, że dostawca serwera posiada pełne uprawnienia oraz logi do serwera, co pozwala na szybką diagnozę problemu i wsparcie.",
  },
  {
    question: "Jak wygląda płatność za projekt?",
    answer:
      "Standardowy proces podział płatności na dwie częsci. Pierwsza część stanowi zadatek, jest to 40% płatne do 3 dni od podpisania umowy. Pozostałe 60% płatne jest do 7 dni od zakończenia realizacji.",
  },
  {
    question:
      "Czy w cenie możemy liczyć na przygotowanie ewentualnego brandingu?",
    answer:
      "Tak, w ramach realizacji możemy określić kolorystykę, typografię oraz podstawowe logo.",
  },
  {
    question: "Czy w cenie jest domena i serwer?",
    answer:
      "Tak, w ramach każdego projektu zapewniamy domenę oraz szybki serwer na rok. Po roku możesz przedłużyć utrzymanie projektu u naszego Partnera - LH.pl bądź wybrać dowolnego innego dostawcę. Z ewentualną migracją pomożemy Państwu na każdym etapie",
  },
  {
    question: "Jakie są dalsze koszty serwera i domeny?",
    answer:
      "Średni koszt utrzymania serwera u naszego Partnera to około 199 PLN netto. Koszt domeny oscyluje między 80 a 120 PLN netto. Pierwsza płatność pojawi się dopiero rok po podpisaniu umowy.",
  },
  {
    question: "Kto będzie dbać o opłacanie serwera oraz domeny?",
    answer:
      "Dbając o niezależność naszych Klientów oraz ich pełną własność projektu Klient zobowiązany jest do uiszczania opłat za serwer oraz domenę. W tej sprawie będą Państwo otrzymywać regularne powiadomienia, aby nic Państwu nie umknęło.",
  },
  {
    question: "Czy zapewniacie teksty i zdjęcia?",
    answer:
      "Tak, jeżeli nie dysponują Państwo materiałami przygotujemy dla Państwa nagłówki, opisy oraz zdjęcia i grafiki.",
  },
  {
    question: "Jaki jest koszt dalszej obsługi?",
    answer:
      "Nie pobieramy opłat za wsparcie po realizacji projektu. Koszty związane z utrzymaniem projektu to serwer i domena. W przypadku chęci rozbudowy projektu o nowe funkcjonalności koszt ustalany jest indywidualnie. Na incydenty reagujemy maksymalnie do 24 godzin. Projekty powiązane są z naszym systemem reagowania. W przypadku, gdy Klient nie ingerował znacząco w projekt (modyfikując infrastrukturę, kod programistyczny) nie pobieramy żadnych opłat za naprawę projektu. W ramach realizacji świadczymy bezpłatne wsparcie techniczne dostępne za pośrednictwem poczty elektronicznej, infolinii, czatu na żywo oraz portalu Klienta.",
  },
  {
    question: "Jakie są opłaty po realizacji projektu za ewentualne zmiany?",
    answer:
      "Drobne zmiany takie jak zmiana tekstu, grafiki bądź adresu mailowego realizujemy bezpłatnie do 3 dni roboczych od zgłoszenia. Zmiany wymagające dużego nakładu pracy, np. nowe funkcjonalności bądź przebudowa interfejsu graficznego rozliczane są indywidualnie.",
  },
  {
    question: "Czy są kopie zapasowe?",
    answer:
      "Tak, nasz Partner dostarczający serwery wykonuje codzienne kopie zapasowe. Kopia zapasowa przechowywana jest do 30 dni.",
  },
  {
    question:
      "Co się stanie, jeśli projekt przestanie działać lub się uszkodzi? Jakie są koszty naprawy?",
    answer:
      "Na incydenty reagujemy maksymalnie do 24 godzin. Projekty powiązane są z naszym systemem reagowania. W przypadku, gdy Klient nie ingerował znacząco w projekt (modyfikując infrastrukturę, kod programistyczny) nie pobieramy żadnych opłat za naprawę projektu. W ramach realizacji świadczymy bezpłatne wsparcie techniczne dostępne za pośrednictwem poczty elektronicznej, infolinii, czatu na żywo oraz portalu Klienta.",
  },
  {
    question: "Co się stanie w przypadku ataku wirusów? Ile kosztuje naprawa?",
    answer:
      "Dostawca serwerów, z którym współpracujemy dysponuje kopią zapasową do 30 dni oraz certyfikacją ISO. Strona dodatkowo zabezpieczona będzie mechanizmami takimi jak reCAPTCHA, czy też Cross-Site Request Forgery Token. Minimalizuje to zagrożenie atakami na stronie internetowej. Odzyskanie kopii zapasowej oraz przywrócenie jej jest bezpłatne.",
  },
  {
    question: "Co się stanie w przypadku ataku wirusów? Ile kosztuje naprawa?",
    answer:
      "Dostawca serwerów, z którym współpracujemy dysponuje kopią zapasową do 30 dni oraz certyfikacją ISO. Strona dodatkowo zabezpieczona będzie mechanizmami takimi jak reCAPTCHA, czy też Cross-Site Request Forgery Token. Minimalizuje to zagrożenie atakami na stronie internetowej. Odzyskanie kopii zapasowej oraz przywrócenie jej jest bezpłatne.",
  },
  {
    question: "Czy jest gwarancja?",
    answer:
      "Tak, każdy projekt obejmowany jest 48-miesięczną gwarancją. Dodatkowo zapewniamy bezterminowe, bezpłatne wsparcie dostępne za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta.",
  },
  {
    question: "Dlaczego wybrać iFil?",
    answer:
      "Zaopiekujemy każdy aspekt Twojego projektu informatycznego. Dbamy nie tylko o interfejs graficzny, czyli to co widzi użytkownik. Przeprowadzamy kompleksową optymalizację pod kątem wydajności, prędkości, dobrych praktyk, pozycjonowania technicznego oraz dostępności. Dysponujemy działem wsparcia dostępnym przez cały tydzień za pomocą maila i czatu na żywo, ale także pod infolinią od poniedziałku do piątku. Podczas realizacji projektu będziesz mieć kontakty jedynie ze swoim opiekunem projektu, dzięki temu unikniesz 'przerzucania' pomiędzy kilku współpracowników. Nie musisz martwić się o techniczne aspekty realizacji, zadbamy o wdrożenie Twojej realizacji do sieci oraz utrzymania jej na serwerze. Co więcej dysponujemy własną aplikacją do ofertowania Let's Offer, do której otrzymasz dożywotni dostęp bezpłatnie w ramach każdej realizacji. Let's Offer pozwoli Ci przenieść swoje oferty dla Klientów na wyższy poziom.",
  },
  {
    question: "Dlaczego nie robimy projektów za 500, 1000, 1500 zł?",
    answer:
      "W sieci można znaleźć twórców stron, którzy podejmują się realizacji projektu za bardzo niskie stawki. W profesjonalny projekt zaangażowanych jest kilku specjalistów takich jak projektant interfejsu graficznego, specjalista UX oraz programista. W związku z tym nie ma możliwości utworzenia profesjonalnego projektu, który przyniesie korzyść Twojej marce za taką stawkę. Wielu twórców stron, sklepów oraz aplikacji internetowych nie dba o takie czynniki jak wydajność, dobre praktyki, pozycjonowanie oraz wsparcie powdrożeniowe. Podczas pracy nie korzystamy z gotowych motywów oraz narzędzki niskiej jakości, każdy projekt poprzedzony jest analizą oraz realizowany 'od zera'. Pamiętaj, że projekt informatyczny to nie tylko to co widzisz na pierwszy rzut oka!",
  },
  {
    question: "Jaki jest limit podstron / zakładek?",
    answer:
      "Nie ma! Nie rozliczamy się na podstawie ilości podstron / zakładek. Zdajemy sobie sprawę, że projekt informatyczny składa się z wielu aspektów. W związku z tym jesteśmy elastyczni i w razie potrzeby tworzymy dodatkowe widoki. W naszych ofertach nie spotkasz się ze wskazaną liczbą podstron.",
  },
  {
    question: "Jak wygląda wsparcie po projekcie?",
    answer:
      "W ramach każdego projektu - bez względu na cenę - zapewniamy bezterminowe wsparcie. Oznacza to, że zawsze możesz liczyć na naszą pomoc w razie jakichkolwiek problemów związanych z projektem. Wsparcie dostępne jest za pośrednictwem infolinii, maila oraz naszego autorskiego panelu Klienta.",
  },
  {
    question: "Czy mogę dokonywać samodzielnie zmian w projekcie?",
    answer:
      "W razie potrzeby samodzielnego wprowadzania zmian w projekcie implementujemy intuicyjny panel administracyjny. Co więcej zapewniamy bezpłatne szkolenie z obsługi panelu oraz zestaw poradników.",
  },
  {
    question: "Jestem osobą nietechniczną, co potrzebuję wiedzieć?",
    answer:
      "Każda nasza realizacja jest kompleksowa, Opiekun Projektu odpowie na wszystkie Twoje pytania oraz przeprowadzi się przez cały proces realizacji bezproblemowo.",
  },
  {
    question: "W jaki sposób prowadzona jest współpraca?",
    answer:
      "Proces dostosowujemy do preferencji klienta. Możemy zarówno prowadzić korespondencję całkowicie mailowo, telefonicznie bądź z wykorzystaniem takich narzędzi jak Microsoft Teams oraz Zoom.",
  },
  {
    question: "Czy projekt będzie dostosowany do urządzeń mobilnych?",
    answer:
      "Tak, każdy projekt posiada pełną responswyność. Dzięki temu wyświetla się poprawnie na wszystkich urządzeniach.",
  },
  {
    question: "Pomożecie mi w marketingu?",
    answer:
      "Tak. Nie jesteśmy jednak agencją marketignową. Specjalizujemy się w realizacji projektów informatycznych. Współpracujemy jednak z firmą WIDOCZNI, która zaopiekuje się marketingiem oraz SEO Twojego projektu.",
  },
  {
    question: "Czym jest aplikacja Let's Offer?",
    answer:
      "Jest to nasz autorski projekt, który pozwala na tworzenie ofert w ramach wcześniej przygotowanego szablonu. Wystarczy uzupełnić ofertę o wybrane parametry i wygenerować link jednym przyciskiem. Ofertę możesz przesłać w jednym linku do Klienta. Dzięki temu wszystkie oferty znajdziesz w jednym miejscu oraz wyróżnisz się na tle pozostałych konkurentów, którzy wciąż wysyłają oferty w PDF bądź w mailu. Dostęp otrzymasz dożytownio w ramach każdej realizacji.",
  },
  {
    question: "Czy zapewniamy domenę oraz serwer?",
    answer:
      "Jak najbardziej. W ramach każdej realizacji zapewniamy zarówno domenę jak i serwer u naszego Partnera LH na rok za darmo. Oczywiście ostateczna decyzja należy do Pani/Pana. Ze swojej strony rekomendujemy firmę LH, ponieważ posiadają wydajne serwery, zapewniają 30-dniowe kopie zapasowe oraz posiadają certyfikat bezpieczeństwa ISO. Jeżeli chodzi o dalsze koszty, po roku występują dwa koszty, pierwszy to domena, jest to około 80 PLN netto, drugi to serwer, tutaj koszt to około 199 PLN netto. W związku z tym całość zamyka się w 270 PLN netto w skali roku.",
  },
  {
    question: "Czy wystawiamy fakturę VAT?",
    answer: "Tak, każda realizacja jest fakturowana.",
  },
  {
    question: "Czy podpisujemy umowę?",
    answer:
      "Tak, umowa ma na celu między innymi przepisanie na Państwa autorskich praw majątkowych. Umowy podpisujemy elektornicznie z wykorzystaniem podpisów elektronicznych, które są prawnie wiążące. Taki podpis zapewniamy Państwu w ramach współpracy.",
  },
  {
    question: "Ile jest poprawek i modyfikacji w ramach realizacji?",
    answer:
      "W tej kwestii jesteśmy bardzo elastyczni, zależy nam na pełnym zadowoleniu Klienta. Nie rozliczamy jako tako sesji poprawkowych, jeżeli coś wymaga zmiany wystarczy zgłoszenie telefoniczne, mailowe, w panelu klienta bądź na live czacie. W samej umowie wprowadzamy generalnie ilość sesji i modyfikacji, jest to jednak zabezpieczenie przed osobami, które nie chcą specjalnie zakończyć realizacji celem uniknięcia płatności. Nie zdarzył się nam jeszcze taki przypadek. Tutaj możemy wpisać nawet po 10 sesji poprawkowych, wedle Pani / Pana uznania.",
  },
  {
    question: "Jak wygląda kwestia pozycjonowania?",
    answer:
      "Ze swojej strony zawsze rekomendujemy Klientom skorzystanie z kampani reklamowych, jeżeli posiadają Państwo budżet na działania marketingowe. Wynika to z tego, że pozycjonowanie często nie przynosi zamierzonych efektów, a koszty w profesjonalnych agencjach są bardzo wysokie. Niestety na ostateczną pozycję witryny w przeglądarkach firmy nie mają znaczenia. Wynika to z tego, że jest to uzależnione w pełni od algorytmów firmy Google, które są tajne. Osobiście sugerujemy Klientom z doświadczenia unikanie usługi pozycjonowania, ponieważ jest to kolokwialnie mówiąc przepalanie środków, które można przeznaczyć w inny filar rozwoju firmy. Oczywiście ostateczna decyzja należy do Pani / Pana.",
  },
  {
    question:
      "Czy zajmujemy się grafiką / pozycjonowaniem / kampaniami reklamowymi?",
    answer:
      "W ramach iFil posiadamy grafika, który jest w stanie przygotować również projekty materiałów marketingowych, wizytówki itp. Od pozycjonowania oraz kampanii reklamowych posiadamy firmę partnerską, która specjalizuje się w tych tematach i na Pani / Pana życzenie przygotuje dedykowaną ofertę.",
  },
  {
    question: "Z kim będę miał kontakt podczas realizacji projektu?",
    answer:
      "W iFil każdy Klient ma dedykowanego opiekuna. Dzięki temu wszystkie kwestie może Pani / Pan zgłaszać do osoby, którą już Pani / Pan zna.",
  },
  {
    question: "Jakie są koszty po zakończeniu projektu?",
    answer:
      "Podstawowy koszt to domena oraz serwer, po roku. U naszego Partnera ten koszt zamyka się w około 270 - 300 złotych netto rocznie. Jeżeli chcieliby Państwo wprowadzić zmiany na stronie takie jak podmiana tekstu czy dodanie zdjęcia to realizujemy je bezpłatnie od ręki w przeciągu 72 godzin od zgłoszenia. W przypadku większej rozbudowy projektu rozliczamy się godzinowo, koszt roboczo-godziny to 120 PLN netto.",
  },
  {
    question: "Jak wygląda wsparcie po projekcie?",
    answer:
      "W ramach realizacji zapewniamy bezterminowe wsparcie za pomocą infolinii, maila, live czatu oraz panelu Klienta. Wszystkie strony naszych Klientów wpięte są do naszego systemu oraz regularnie aktualizowane. Nasz partner techniczny zapewnia natomiast wsparcie serwerowe oraz 30-dniowe kopie zapasowe. Jeżeli będzie Pani / Pan chciał zmienić jakiś tekst na stronie bądź zdjęcie wystarczy przesłać taką dyspozycję do nas. W przypadku większej rozbudowy projektu rozliczamy się godzinowo, koszt roboczo-godziny to 120 PLN netto.",
  },
  {
    question: "Czy będę mógł samodzielnie wprowadzać zmiany w projekcie?",
    answer:
      "Jeżeli chce Pani / Pan zarządzać projektem samodzielenie to jak najbardziej możemy wszystko przygotować pod takie działania. Dostęp do projektu będzie możliwy z poziomu intuicyjnego panelu administracyjnego, który uzależniony jest od wybranego wariantu w ofercie. Jeżeli zdecydują się Państwo na wariant wykorzystujący gotowy system zarządzania treścią będzie to WordPress, jeżeli jednak preferują Państwo indywidualny system to zakodujemy taki dla Państwa.",
  },
  {
    question:
      "Czy podpinamy social media / analitykę / live chat / galerie / filmy?",
    answer: "Tego typu działania są u nas standardem, jak najbardziej.",
  },
  {
    question: "Czy zajmujemy się obróbką zdjęć?",
    answer: "Tak, nasz grafik może zająć się obróbką zdjęć.",
  },
  {
    question: "W jakim formacie należy przesłać zdjęcia?",
    answer:
      "Zdjęcia może Pani / Pan przesłać w dowolnym formacie, my je już skompresujemy bezstratnie oraz przygotujemy do osadzenia na stronie, tak aby nie dodawać Pani / Panu dodatkowej pracy.",
  },
];

function FaqList() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredQuestionsAndAnswers = questionsAndAnswers.filter((item) =>
    (item.question + item.answer)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <section className="min-h-screen flex flex-col items-center justify-center p-6 lg:p-12 relative w-full">
      <div className="absolute top-6 left-6 flex flex-row items-center justify-start gap-6 z-50">
        <a href="/office" className="button-dashboard active">
          Powrót na start
        </a>
      </div>
      <div className="flex flex-col items-center justify-center h-full p-6 lg:p-12 mx-auto w-full max-w-[1000px] z-50">
        {/* Wyszukiwarka */}
        <input
          type="text"
          placeholder="Wyszukaj pytania i odpowiedzi..."
          className="w-full p-3 mb-6 text-black rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="overflow-y-auto h-[60vh] w-full">
          {/* Lista pytań i odpowiedzi */}
          {filteredQuestionsAndAnswers.map((item, index) => (
            <div
              key={index}
              className="w-full group cursor-pointer transition duration-300 ease-linear hover:border-opacity-100 flex flex-col items-start justify-start p-6 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20 mb-4"
              onClick={() => toggleAnswer(index)}
            >
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-xl font-semibold">{item.question}</p>
                <CiCirclePlus
                  className={`text-3xl transition-transform ${
                    openIndex === index ? "rotate-45" : ""
                  }`}
                />
              </div>
              {openIndex === index && (
                <p className="text-white opacity-60 text-left pt-6">
                  {item.answer}
                </p>
              )}
            </div>
          ))}

          {/* Komunikat, gdy brak wyników */}
          {filteredQuestionsAndAnswers.length === 0 && (
            <p className="text-white opacity-60 text-center mt-6">
              Nie znaleziono żadnych wyników.
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

export default FaqList;
